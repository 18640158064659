import axios from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import {ButtonType} from "../constant/MsTeamsConstants";

const clientContext = async (teamsClient, timeout = 10000) => {
    return new Promise((resolve, reject) => {
        let shouldReject = true;
        teamsClient.app.getContext().then((teamsContext) => {
            shouldReject = false;
            resolve({
                        ...teamsContext,
                    });
        });
        setTimeout(() => {
            if (shouldReject) {
                console.error(
                    "Error getting context: Timeout. Make sure you are running the app within teams context and have initialized the sdk"
                );
                reject("Error getting context: Timeout");
            }
        }, timeout);
    });
};

const getAuthCode = (teamsClient) => {
    return new Promise((resolve, reject) => {
        teamsClient.authentication
            .getAuthToken()
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                console.error("Failed to get auth: ", error);
                reject(error);
            });
    });
};

const getArticleDetails = async (context, documentId) => {
    return axios.get(`${process.env.REACT_APP_MSTEAMS_BOT_URL}/api/articleDetails?documentId=${encodeURIComponent(documentId)}&tenantId=${encodeURIComponent(context.user.tenant.id)}`);
};

const handleReturnToResult = async () => {
    microsoftTeams.dialog.submit({
                                     "data": {
                                         "cardType": "ArticleDetails",
                                         "buttonType": ButtonType.returnToResults
                                     }
                                 });
};

const handleThisResolvedMyIssue = async (queryId, resultId) => {
    microsoftTeams.dialog.submit({
                                     "data": {
                                         "cardType": "ArticleDetails",
                                         "buttonType": ButtonType.thisResolvedMyIssue,
                                         "queryId": queryId,
                                         "relevanceFeedbackItem": {
                                             "resultId": resultId,
                                             "relevanceValue": "RELEVANT"
                                         }
                                     }
                                 });
};

const handleThumbsClicked = async (queryId, resultId, context, buttonType) => {
    return axios.post(`${process.env.REACT_APP_MSTEAMS_BOT_URL}/api/articleSuggestionFeedback?tenantId=${encodeURIComponent(context.user.tenant.id)}&userId=${encodeURIComponent(context.user.id)}&buttonType=${encodeURIComponent(buttonType)}&queryId=${encodeURIComponent(queryId)}&resultId=${encodeURIComponent(resultId)}`);
};

export { clientContext, getAuthCode, getArticleDetails, handleReturnToResult, handleThisResolvedMyIssue, handleThumbsClicked};
