import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as microsoftTeams from "@microsoft/teams-js";
import MsTeamsContextProvider from "./provider/MsTeamsContextProvider";
import reportWebVitals from "./reportWebVitals";

microsoftTeams.app.initialize();

ReactDOM.render(
    <React.StrictMode>
        <MsTeamsContextProvider>
            <App />
        </MsTeamsContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
