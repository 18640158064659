// eslint-disable-next-line import/no-anonymous-default-export
export default {
    timeout: 10000,
    refreshInterval: 3000,
};

export const ButtonType = {
    returnToResults: 0,
    thisResolvedMyIssue: 1,
    thumbsUp: 2,
    thumbsDown: 3,
}
