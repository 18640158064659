import React from "react";

interface TeamsContextProps {
    teamsContext: string;
    authCode: string;
}

const MsTeamsContext = React.createContext({} as TeamsContextProps);

export default MsTeamsContext;
