import {DefaultPalette} from "@fluentui/react";

export const outLineButtonsStyles = {
    root: {
        background: DefaultPalette.purple,
        color: DefaultPalette.white,
        borderRadius: 3
    },
};

export const buttonsStyles = {
    root: {
        background: DefaultPalette.white,
        color: DefaultPalette.purple,
        borderRadius: 3
    },
};

export const spinnerStyles = {
    root: {
        padding: `${150}px ${0}px ${0}px ${0}px`, // `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`
    },
};
