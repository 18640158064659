import React, {useState, useContext, useEffect, useRef} from "react";
import MsTeamsContext from "../context/MsTeamsContext";
import { getArticleDetails, handleReturnToResult, handleThisResolvedMyIssue, handleThumbsClicked} from "../service/MsTeamsClientService";
import {DefaultButton, IconButton, Spinner, SpinnerSize, Stack} from "@fluentui/react";
import {THUMBS_DOWN_OUTLINE, THUMBS_UP_OUTLINE, THUMBS_UP, THUMBS_DOWN} from "../constant/ImagesConstants";
import {buttonsStyles, spinnerStyles, outLineButtonsStyles} from "../constant/StyleConstants";
import {RETURN_TO_RESULT, THIS_RESOLVED_MY_ISSUE} from "../constant/IframeTextsConstants";
import {ButtonType} from "../constant/MsTeamsConstants";
import {headerStyle, thumbsButtonsStyle, defaultButtonsStyle, contentStyle, titleStyle} from "./Article.css"

function Article(props) {
    const {teamsContext, authCode} = useContext(MsTeamsContext);
    const [initialized, setInitialized] = useState(false);
    const [contentMarginInitialized, setContentMarginInitialized] = useState(false);
    const [answer, setAnswer] = useState('');
    const [title, setTitle] = useState('');
    const [queryId, setQueryId] = useState('');
    const [resultId, setResultId] = useState('');
    const [resourceBundle, setResourceBundle] = useState('');
    const [isThumbsUpFilled, setIsThumbsUpFilled] = useState(false);
    const [isThumbsDownFilled, setIsThumbsDownFilled] = useState(false);
    const [showLoadingSymbol, setShowLoadingSymbol] = useState(true);
    const headerRef = useRef(null);
    const contentRef = useRef(null);

    const getQueryStringParam = (url, p) => {
        const query = new URLSearchParams(url);
        const param = query.get(p)
        return param || '';
    };

    const handleThumbsUpAndDownClicked = (buttonType) => {
        const thumbsUpClicked = buttonType === ButtonType.thumbsUp;
        const thumbsDownClicked = buttonType === ButtonType.thumbsDown;

        const shouldToggleThumbsUp = thumbsUpClicked || ( thumbsDownClicked && isThumbsUpFilled)
        const shouldToggleThumbsDown = thumbsDownClicked || (thumbsUpClicked && isThumbsDownFilled)

        if ((thumbsUpClicked && !isThumbsUpFilled) || (thumbsDownClicked && !isThumbsDownFilled)) {
            handleThumbsClicked(queryId, resultId, teamsContext, buttonType);
        }

        if (shouldToggleThumbsUp) {
            setIsThumbsUpFilled(!isThumbsUpFilled);
        }

        if (shouldToggleThumbsDown) {
            setIsThumbsDownFilled(!isThumbsDownFilled);
        }
    };

    const changeContentPositionAccordingToHeader = () => {
        let height = headerRef.current.offsetHeight;
        contentRef.current.style.marginTop = height + 'px';
    };

    useEffect(() => {
        window.addEventListener('resize', ()=>{
            changeContentPositionAccordingToHeader();
        });
        return () => {
            window.removeEventListener('resize', ()=>{});
        }
    }, [])

    useEffect(() => {
        if(headerRef.current && contentRef.current && !contentMarginInitialized){
            changeContentPositionAccordingToHeader();
            setContentMarginInitialized(true);
        }
    })

    if (teamsContext && authCode) {
        if (!initialized) {
            let documentId = getQueryStringParam(window.location.search, "documentId");
            getArticleDetails(teamsContext, documentId).then((res) => {
                setAnswer(res.data.answer.replaceAll(">&nbsp;<", "><").replaceAll("><br /><", "><").replaceAll(/href/g, "target='_blank' href"));
                setTitle(res.data.title);
                setShowLoadingSymbol(false);
            });
            setResourceBundle(JSON.parse(getQueryStringParam(window.location.search, "articleResourceBundle")));
            setQueryId(getQueryStringParam(window.location.search, "queryId"));
            setResultId(getQueryStringParam(window.location.search, "resultId"));
            setInitialized(true);
        }
    }

    return (
        <>
            {showLoadingSymbol && <Spinner labelPosition="top" size={SpinnerSize.large} styles={spinnerStyles}/>}
            {!showLoadingSymbol && <Stack horizontal horizontalAlign="start">
                <div>
                    <div ref={headerRef} className={"headerStyle"}>
                        <h3 className={"titleStyle"}>{title}</h3>
                        <div className={"thumbsButtonsStyle"}>
                            <IconButton onClick={()=>{handleThumbsUpAndDownClicked(ButtonType.thumbsUp)}}>
                                <img src={isThumbsUpFilled? THUMBS_UP : THUMBS_UP_OUTLINE} width={24} alt={""}/>
                            </IconButton>
                            <IconButton onClick={()=>{handleThumbsUpAndDownClicked(ButtonType.thumbsDown)}}>
                                <img src={isThumbsDownFilled? THUMBS_DOWN : THUMBS_DOWN_OUTLINE} width={24} alt={""}/>
                            </IconButton>
                        </div>
                    </div>

                    <div ref={contentRef} className={"contentStyle"} dangerouslySetInnerHTML={{__html: answer}}/>

                    <div className={"defaultButtonsStyle"}>
                        <DefaultButton text={resourceBundle[RETURN_TO_RESULT]} onClick={handleReturnToResult} styles={buttonsStyles}/>
                        <DefaultButton text={resourceBundle[THIS_RESOLVED_MY_ISSUE]} onClick={()=>{handleThisResolvedMyIssue(queryId, resultId)}} styles={outLineButtonsStyles}/>
                    </div>
                </div>
            </Stack>}
        </>
    );
}

export default Article;
