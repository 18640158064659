import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Article from "./components/Article";

function App() {
    return (
        <Suspense fallback="loading">
            <Router>
                <Route exact path="/article" component={Article}/>
            </Router>
        </Suspense>
    );
}

export default App;
